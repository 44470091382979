import React from "react"
import imgPortadaGuide from "@components/pageGuide/guides/guide78/image/imgPortadaGuia.png"
import img1 from "@components/pageGuide/guides/guide78/image/img.png"
import slider1 from "@components/pageGuide/guides/guide78/image/silder1.png"
import slider2 from "@components/pageGuide/guides/guide78/image/silder2.png"
import slider3 from "@components/pageGuide/guides/guide78/image/silder3.png"


const linkForm = (
  <span>
    Al registrarte, aceptas los términos y condiciones de nuestra{" "}
    <a
      style={{ color: "#2C5C82", textDecoration: "none" }}
      href="https://beexcc.com/politica-seguridad-privacidad.html"    >
      Política de Privacidad.
    </a>
  </span>
)

const data = {
  start: {
    support: "Guía | Inmobiliarias",
    title: <h1 className="titles-underlined-ebook ebook-ventas">
      Cómo agilizar el proceso de consultas sobre inmuebles
    </h1>,
    description: <p className="text-h2-ebook guide__description">
      El proceso de consultas sobre inmuebles debe ser rápido y eficiente para facilitar la comunicación con los interesados. Organiza la información de cada propiedad de forma accesible y clara. Usa canales adecuados para responder mensajes sin demoras.
    </p>,
    classtext: "guide__description-container",
    link: linkForm,
    href: "/ebook-9.pdf",
    brochure: "Guía",
    img: imgPortadaGuide,
  },

  slider: {
    title: "¿Qué encontrarás?",
    underlined: "encontrarás?",
    checklist: [
      {
        key: 1,
        text: "Automatización y chatbots.",
      },
      {
        key: 2,
        text: "Centralización de información."
      },
      {
        key: 3,
        text: "Entrenamiento de equipos.",
      },
      {
        key: 4,
        text: "Gestión de leads y priorización de consultas.",
      },
      {
        key: 5,
        text: "Estrategias de respuesta proactiva.",
      },
    ],
    listPictures: [
      {
        key: 1,
        img: slider1,
      },
      {
        key: 2,
        img: slider2,
      },
      {
        key: 3,
        img: slider3,
      },
    ],
  },
  description: {
    cards: [
      {
        text: <p>
          Recuerda que, con esta guía, podrás saber cómo automatizar respuestas para brindar detalles sin perder tiempo. Asegúrate de incluir opciones para agendar visitas y ofrecer seguimiento a cada solicitud.
          <br /><br />
          Por último, descubre más detalles, ejemplos prácticos y herramientas recomendadas descargándola. Optimiza la gestión de consultas y mejora la experiencia de los interesados.
        </p>,
        photo: img1,
        class: true,
      },
    ],
  },
}

export default data
